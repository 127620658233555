<template>
  <spinner-container :loading="localLoading">
    <bread-cump :cumps="$route.meta.breadcrumps" />

    <v-container fluid>
      <v-card class="card--shadow">
        <v-card-text>
          <patient-info :patient="patient" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col lg="12">
          <v-expansion-panels flat accordion>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="white--text"
                color="purple accent-4"
              >
                Pre Enroll Form
              </v-expansion-panel-header>
              <enroll-form
                @emitData="createPreEnroll"
                :patient_id="$route.params.id"
                :isPreEnrollForm="true"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12">
          <v-card class="card--shadow">
            <pre-enroll-list
              :tbHeaders="tbHeaders"
              :preEnrollData="preEnrollData"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import SpinnerContainer from "@/components/container/SpinnerContainer";
import PreEnrollList from "@/components/patient/enroll/PreEnrollList";
import EnrollForm from "@/components/patient/enroll/EnrollForm";
import BreadCump from "@/components/navigation/BreadCump";
import { mapState } from "vuex";
import PatientInfo from "../component/patientInfo.vue";

export default {
  components: {
    SpinnerContainer,
    PreEnrollList,
    EnrollForm,
    BreadCump,
    PatientInfo,
  },
  data: () => ({
    tbHeaders: [
      { text: "No", value: "no" },
      { text: "Nominated", value: "nominated" },
      { text: "Support", value: "support" },
      { text: "Month", value: "support_month" },
      { text: "Year", value: "support_year" },
      { text: "Date", value: "date" },
      { text: "Type", value: "type" },
      { text: "Delegated", value: "delegated" },
      { text: "Signature", value: "signature" },
      { text: "Reimbursement", value: "repay" },
      { text: "Month", value: "month_type" },
      { text: "Remark", value: "remark" },
      { text: "Actions", value: "action" },
      { text: "Detail", value: "detail" },
    ],
  }),
  computed: {
    ...mapState("PreEnroll", ["preEnrollData"]),
    ...mapState("Patient", ["patient"]),
    ...mapState("Loading", ["localLoading"]),
  },
  methods: {
    createPreEnroll(formData) {
      this.$store.dispatch("PreEnroll/createPreEnroll", formData).then(() => {
        console.log("success");
      });
    },
  },
  created() {
    this.$store.dispatch("Loading/changeLocalLoading");
    this.$store
      .dispatch("Patient/getPatient", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("PreEnroll/getPreEnroll", this.$route.params.id)
          .then(() => {
            this.$store.dispatch("Loading/changeLocalLoading");
          });
      });
  },
};
</script>

<style scoped>
.patient-info {
  letter-spacing: 1px !important;
}
</style>
