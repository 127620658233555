<template>
  <div>
    <confirm-box
      :dialog="dialog"
      @confirmed="deleteEnrollData"
      @cancel="cancel"
    />
    <v-data-table
      :headers="tbHeaders"
      :items="dataHandler"
      :loading="loading"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :page.sync="page"
      item-key="id"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead class="header">
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="header--text"
            >
              <span v-if="header.value">{{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ index, item }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td v-if="item.nominated == 2">Yes</td>
          <td v-else>No</td>

          <td v-if="item.support == 2">Yes</td>
          <td v-else-if="item.support == 1">No</td>
          <td v-else>--</td>

          <td>{{ item.month_text }}</td>
          <td>{{ item.year_text }}</td>
          <td>{{ item.date }}</td>
          <td>
            <span v-if="item.type == 1">Signature</span>
            <span v-if="item.type == 2">ATM</span>
            <span v-if="item.type == 3">Mobile</span>
          </td>
          <td>{{ item.delagated == 1 ? "No" : "Yes" }}</td>
          <td @click="viewSignDialog(item)" v-if="item.signature">
            <img
              width="80"
              height="80"
              :src="item.signature"
              v-show="item.type == 1"
            />
          </td>
          <td v-else></td>
          <td>{{ item.repay == 1 ? "No" : "Yes" }}</td>
          <td>
            <span v-if="item.month_type == 1">Month 1</span>
            <span v-if="item.month_type == 2">Month 2</span>
            <span v-if="item.month_type == 3">Month 1+2</span>
          </td>
          <td>{{ item.remark }}</td>
          <td>
            <v-btn
              v-show="canDelete(item)"
              @click="openConfrimBox(item)"
              icon
              color="red lighten-1"
              class="white--text"
              depressed
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              @click="viewDetailDialog(item)"
              icon
              color="orange lighten-1"
              class="white--text"
              depressed
            >
              <v-icon>mdi-apps</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center py-3">
      <v-pagination
        color="purple accent-4"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    <detail-dialog
      :enrollEachData="enrollEachData"
      :dialog="detailDialog"
      @cancel="closeDetailDialog"
    ></detail-dialog>
    <sign-dialog
      :enrollEachData="enrollEachData"
      :dialog="signDialog"
      @cancel="closeSignDialog"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SignDialog from "@/components/patient/enroll/modal/SignDialog";
import ConfirmBox from "@/components/notification/ConfirmBox";
import DetailDialog from "@/components/patient/enroll/modal/DetailDialog";

export default {
  props: {
    preEnrollData: {
      type: Array,
    },
    tbHeaders: {
      type: Array,
    },
  },
  components: {
    SignDialog,
    ConfirmBox,
    DetailDialog,
  },
  data: () => ({
    pageCount: 0,
    page: 1,
    itemsPerPage: 10,
    item: {},
    dialog: false,
    signDialog: false,
    detailDialog: false,
    enrollEachData: {},
  }),
  computed: {
    ...mapState("Loading", ["loading"]),
    ...mapState("InitialData", ["months"]),
    ...mapState("InitialData", ["years"]),
    dataHandler() {
      const enrollData = this.preEnrollData.map((item) => {
        //mapping month id
        const months = this.months.find((month) => {
          return item.support_month == month.value;
        });
        item.month_text = months.text;
        //mapping year id
        const years = this.years.find((year) => {
          return item.support_year == year.text;
        });
        item.year_text = years.text;

        return item;
      });
      return enrollData;
    },
  },
  methods: {
    openConfrimBox(item) {
      this.item = item;
      this.dialog = !this.dialog;
    },
    deleteEnrollData() {
      this.$store
        .dispatch("PreEnroll/delete", this.item)
        .then(() => (this.dialog = !this.dialog));
    },
    viewDetailDialog(item) {
      this.detailDialog = !this.detailDialog;
      this.enrollEachData = item;
    },
    viewSignDialog(item) {
      this.signDialog = !this.signDialog;
      this.enrollEachData = item;
    },
    closeSignDialog() {
      this.signDialog = !this.signDialog;
    },
    closeDetailDialog() {
      this.detailDialog = !this.detailDialog;
    },
    cancel() {
      this.dialog = !this.dialog;
    },
    canDelete(item) {
      var createdDate = new Date(item.created_at);
      var today = new Date();
      var diff = (today.getTime() - createdDate.getTime()) / 1000;
      diff /= 60 * 60;
      diff = Math.abs(Math.round(diff));

      if (diff < 24) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
